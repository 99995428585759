import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDTypography from "components/MDTypography";

export const ConfirmationComponent = ({ message, handleConfirm, handleClose }) => (
	<MDBox pb={2}>
		<MDTypography pb={2} px={2} variant="p" display="flex" width="100%">
			{message}
		</MDTypography>
		<MDBox px={2} sx={{ display: "flex", justifyContent: "end", gap: "8px" }}>
			<MDButton variant="contained" color="secondary" onClick={handleClose}>
				Cancel
			</MDButton>
			<MDButton variant="contained" color="primary" onClick={handleConfirm}>
				Confirm
			</MDButton>
		</MDBox>
	</MDBox>
);
