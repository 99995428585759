import { setAuthToken } from "utils/authToken";
import api from "../utils/axios";

export const signIn = async (credentials) => {
	const response = await api.post("/users/login", credentials);
	return response.data;
};

export const signOut = () => {
	setAuthToken(null);
	window.location.href = "/connect";
};
