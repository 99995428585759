import React, { useEffect } from "react";

import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";

import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

import DashboardLayout from "customComponents/LayoutContainers/DashboardLayout";
import DataTable from "customComponents/Tables/DataTable";

import settingsDataTable from "layouts/settings/data/settingsDataTable";
import { getSettings } from "services/settingsService";

function Settings() {
	const [settings, setSettings] = React.useState([]);

	const { columns, rows } = settingsDataTable(settings);

	useEffect(() => {
		async function fetchSettings() {
			var mySettings = [];
			const result = await getSettings();
			console.log(result);
			if (result !== undefined) {
				result.forEach((item) => {
					const setting = {
						id: item.id,
						label: item.label,
						value: item.value,
					};
					mySettings.push(setting);
				});
				setSettings(mySettings);
			}
		}

		fetchSettings();
	}, []);

	return (
		<DashboardLayout>
			<MDBox py={3}>
				<Grid container spacing={6}>
					<Grid item xs={12}>
						<Card>
							<MDBox
								mx={2}
								mt={-3}
								py={3}
								px={2}
								sx={{
									display: "flex",
									flexDirection: "row",
									justifyContent: "space-between",
									alignItems: "center",
								}}
								variant="gradient"
								bgColor="dark"
								borderRadius="lg"
								minHeight="85px"
								coloredShadow="dark">
								<MDTypography variant="h6" color="white" textAlign="middle">
									Settings
								</MDTypography>
							</MDBox>
							<MDBox sx={{ width: "100%" }} pt={3}>
								<DataTable
									table={{ columns, rows }}
									isSorted={false}
									entriesPerPage={false}
									showTotalEntries={false}
									noEndBorder
								/>
							</MDBox>
						</Card>
					</Grid>
				</Grid>
			</MDBox>
		</DashboardLayout>
	);
}

export default Settings;
