import SignIn from "layouts/authentication/sign-in";
import Icon from "@mui/material/Icon";
import Settings from "layouts/settings";
import Testimonial from "layouts/testimonial";
import Blog from "layouts/blog";
import Subscriber from "layouts/subscriber";
import ContentEditor from "layouts/settings/contentEditor";
import { getPrivacyPolicy } from "services/settingsService";
import { savePrivacyPolicy } from "services/settingsService";
import { getTermsAndConditions } from "services/settingsService";
import { saveTermsAndConditions } from "services/settingsService";

const routes = [
	{
		type: "collapse",
		position: "top",
		name: "Newsletter",
		key: "newsletter",
		icon: <Icon fontSize="small">article</Icon>,
		route: "/newsletter",
		component: <Blog />,
	},
	{
		type: "collapse",
		position: "top",
		name: "Testimonials",
		key: "testimonials",
		icon: <Icon fontSize="small">group</Icon>,
		route: "/testimonials",
		component: <Testimonial />,
	},
	{
		type: "collapse",
		position: "top",
		name: "Subscribers",
		key: "subscribers",
		icon: <Icon fontSize="small">mail</Icon>,
		route: "/subscribers",
		component: <Subscriber />,
	},
	{
		type: "collapse",
		position: "top",
		name: "Privacy Policy",
		key: "privacyPolicy",
		route: "/privacyPolicy",
		icon: <Icon fontSize="small">privacy_tip</Icon>,
		component: (
			<ContentEditor
				label="Privacy Policy"
				getContent={getPrivacyPolicy}
				saveContent={savePrivacyPolicy}></ContentEditor>
		),
	},
	{
		type: "collapse",
		position: "top",
		name: "Terms & Conditions",
		key: "termsAndConditions",
		route: "/termsAndConditions",
		icon: <Icon fontSize="small">gavel</Icon>,
		component: (
			<ContentEditor
				label="Terms and Conditions"
				getContent={getTermsAndConditions}
				saveContent={saveTermsAndConditions}></ContentEditor>
		),
	},
	{
		type: "collapse",
		position: "top",
		name: "Settings",
		key: "settings",
		icon: <Icon fontSize="small">settings</Icon>,
		route: "/settings",
		component: <Settings />,
	},
	{
		type: "collapse",
		position: "bottom",
		name: "Sign out",
		key: "sign-out",
		icon: <Icon fontSize="small">logout</Icon>,
		route: "/connect",
		component: <SignIn />,
	},
];

export default routes;
