import React, { useEffect, useRef } from "react";
import PhotoCamera from "@mui/icons-material/PhotoCamera";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";

function ImageUpload({ onImageUpload, defaultImage }) {
	const imageUploadRef = useRef();
	const selectedImageRef = useRef(defaultImage || null);

	useEffect(() => {
		if (!selectedImageRef.current && defaultImage) {
			const img = new Image();
			img.src = defaultImage;
			img.onload = () => (selectedImageRef.current = img.src);
			img.onerror = () => (selectedImageRef.current = null);
		} else {
			if (!defaultImage) {
				selectedImageRef.current = null;
			}
		}
	}, [defaultImage]);

	const handleImageChange = (e) => {
		if (e.target.files && e.target.files[0]) {
			let img = e.target.files[0];
			selectedImageRef.current = URL.createObjectURL(img);
			onImageUpload(img);
		}
	};

	const handleImageUpload = () => {
		imageUploadRef.current.click();
	};

	return (
		<MDBox>
			<input
				id="imageUpload"
				type="file"
				accept="image/*"
				ref={imageUploadRef}
				hidden
				onChange={handleImageChange}
			/>
			{!selectedImageRef.current ? (
				<MDButton iconOnly={true} color="primary" onClick={handleImageUpload}>
					<PhotoCamera fontSize="large" />
				</MDButton>
			) : (
				<MDBox
					sx={{ width: "100%", display: "flex", justifyContent: "center", alignItems: "center" }}
					onClick={handleImageUpload}>
					<img
						src={selectedImageRef.current}
						alt="Selected"
						style={{
							width: "100%",
							height: "auto",
							maxWidth: "175px",
							maxHeight: "175px",
							objectFit: "contain",
						}}
					/>
				</MDBox>
			)}
		</MDBox>
	);
}

export default ImageUpload;
