import api from "../utils/axios";

export const createPost = async (post, image, pdf) => {
	const formData = new FormData();

	formData.append("post", JSON.stringify(post));

	if (image) {
		formData.append("image", image);
	}

	if (pdf) {
		formData.append("pdf", pdf);
	}

	const response = await api.post("/blog", formData, {
		headers: {
			"Content-Type": "multipart/form-data",
		},
	});
	return response.data;
};

export const getPosts = async (page, pageSize) => {
	const response = await api.get("/blog", { params: { page, pageSize } });
	return response.data;
};

export const getPostById = async (id) => {
	const response = await api.get(`/blog/${id}`);
	return response.data;
};

export const updatePost = async (id, post, image, pdf) => {
	const formData = new FormData();

	formData.append("post", JSON.stringify(post));

	if (image) {
		formData.append("image", image);
	}

	if (pdf) {
		formData.append("pdf", pdf);
	}

	const response = await api.put(`/blog/${id}`, formData, {
		headers: {
			"Content-Type": "multipart/form-data",
		},
	});
	return response.data;
};

export const deletePost = async (id) => {
	const response = await api.delete(`/blog/${id}`);
	return response.data;
};
