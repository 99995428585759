import React, { useState } from "react";
import { Snackbar, Alert } from "@mui/material";
import { SnackbarContext } from "./SnackbarContext";

export const SnackbarProvider = ({ children }) => {
	const [open, setOpen] = useState(false);
	const [message, setMessage] = useState("");
	const [severity, setSeverity] = useState("info");

	const showSnackbar = (message, severity = "info") => {
		setMessage(message);
		setSeverity(severity);
		setOpen(true);
	};

	const closeSnackbar = () => {
		setOpen(false);
	};

	return (
		<SnackbarContext.Provider value={showSnackbar}>
			{children}
			<Snackbar
				open={open}
				autoHideDuration={3000}
				onClose={closeSnackbar}
				anchorOrigin={{ vertical: "top", horizontal: "right" }}>
				<Alert onClose={closeSnackbar} severity={severity} sx={{ width: "100%" }}>
					{message}
				</Alert>
			</Snackbar>
		</SnackbarContext.Provider>
	);
};
