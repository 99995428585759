import api from "../utils/axios";

export const subscribe = async (subscriber) => {
	const response = await api.post("/subscriber/subscribe", subscriber);
	return response.data;
};

export const getSubscribers = async (page, pageSize) => {
	const response = await api.get("/subscriber/subscribers", { params: { page, pageSize } });
	return response.data;
};

export const unsubscribe = async (email) => {
	const response = await api.post("/subscriber/unsubscribe", { email });
	return response.data;
};

export const sendNewsletter = async (id) => {
	const response = await api.post("/subscriber/send", { id: id });
	return response.data;
};
