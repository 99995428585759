// Material Dashboard 2 React base styles
import colors from "assets/theme-dark/base/colors";
import boxShadows from "assets/theme-dark/base/boxShadows";
import borders from "assets/theme-dark/base/borders";

const { background } = colors;
const { md } = boxShadows;
const { borderRadius } = borders;

const tableContainer = {
	styleOverrides: {
		root: {
			backgroundColor: background.card,
			boxShadow: md,
			borderRadius: borderRadius.xl,
		},
	},
};

export default tableContainer;
