// Material Dashboard 2 React Base Styles
import colors from "assets/theme-dark/base/colors";

const { transparent } = colors;

const textField = {
	styleOverrides: {
		root: {
			backgroundColor: transparent.main,
		},
	},
};

export default textField;
