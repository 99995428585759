import React, { useContext, useEffect, useState } from "react";

import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// Material Dashboard 2 React example components
import DashboardLayout from "customComponents/LayoutContainers/DashboardLayout";

// Data
import { SnackbarContext } from "components/MDNotification/SnackbarContext";
import { getSubscribers } from "services/subscriberService";
import SubscriberDataTable from "./data/SubscriberDataTable";

function Subscriber() {
	const showNotification = useContext(SnackbarContext);
	const [subscribers, setSubscribers] = useState([]);

	useEffect(() => {
		async function fetchSubscribers() {
			try {
				const result = await getSubscribers();
				if (result) {
					setSubscribers(result);
				}
			} catch (err) {
				showNotification("Fetch subscribers failed", "error");
			}
		}
		fetchSubscribers();
	}, []);

	return (
		<DashboardLayout>
			<MDBox py={3}>
				<Grid container spacing={6}>
					<Grid item xs={12}>
						<Card>
							<MDBox
								mx={2}
								mt={-3}
								py={3}
								px={2}
								sx={{
									display: "flex",
									flexDirection: "row",
									justifyContent: "space-between",
									alignItems: "center",
								}}
								minHeight="85px"
								variant="gradient"
								bgColor="dark"
								borderRadius="lg"
								coloredShadow="dark">
								<MDTypography variant="h6" color="white" textAlign="middle">
									Subscribers
								</MDTypography>
							</MDBox>
							<MDBox pt={3}>
								<SubscriberDataTable subscribers={subscribers}></SubscriberDataTable>
							</MDBox>
						</Card>
					</Grid>
				</Grid>
			</MDBox>
		</DashboardLayout>
	);
}

export default Subscriber;
