// Material Dashboard 2 React base styles
import borders from "assets/theme/base/borders";

// Material Dashboard 2 React helper functions
import pxToRem from "assets/theme/functions/pxToRem";

const { borderRadius } = borders;

const tableHead = {
	styleOverrides: {
		root: {
			display: "block",
			padding: `${pxToRem(16)} ${pxToRem(16)} 0  ${pxToRem(16)}`,
			borderRadius: `${borderRadius.xl} ${borderRadius.xl} 0 0`,
		},
	},
};

export default tableHead;
