import React, { useContext, useEffect, useState } from "react";

import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";
import { SnackbarContext } from "components/MDNotification/SnackbarContext";

import DataTable from "customComponents/Tables/DataTable";

import { Icon } from "@mui/material";

import ResponsiveDialog from "layouts/generalTable/responsiveDialog";
import { ConfirmationComponent } from "layouts/generalTable/confirmationDialog";
import { unsubscribe } from "services/subscriberService";

export default function SubscriberDataTable(props) {
	const showNotification = useContext(SnackbarContext);
	const [confirmationOpen, setConfirmationOpen] = useState(false);
	const [subscriber, setSubscriber] = useState({});
	const [subscribers, setSubscribers] = useState([]);

	useEffect(() => {
		setSubscribers(props.subscribers);
	}, [props.subscribers]);

	const handleConfirmationOpen = (data) => {
		setSubscriber(data);
		setConfirmationOpen(true);
	};

	const handleDelete = async (item) => {
		try {
			const response = await unsubscribe(item.email);
			if (response) {
				setSubscribers(subscribers.filter((x) => x.email !== item.email));
				setConfirmationOpen(false);
				showNotification("Subscriber was successfully removed.", "success");
			}
		} catch (err) {
			showNotification("There was a problem removing the subscriber. Please try again.", "error");
		}
	};

	const columns = [
		{ Header: "E-mail", accessor: "email", align: "left" },
		{ Header: "Subscribed At", accessor: "subscribedAt", align: "left" },
		{ Header: "Actions", accessor: "action", align: "center" },
	];

	return (
		<>
			<DataTable
				table={{
					columns,
					rows:
						subscribers?.length > 0
							? subscribers?.map((item) => ({
									email: (
										<MDTypography variant="p" color="text">
											{item.email}
										</MDTypography>
									),
									subscribedAt: (
										<MDTypography variant="p" color="text">
											{item.subscribedAt}
										</MDTypography>
									),
									action: (
										<MDBox lineHeight={1} sx={{ display: "flex", gap: "8px" }} textAlign="left">
											<MDButton
												iconOnly={true}
												variant="contained"
												size="medium"
												color="secondary"
												fontWeight="medium"
												onClick={(e) => handleConfirmationOpen(item)}>
												<Icon fontSize="medium">delete</Icon>
											</MDButton>
										</MDBox>
									),
							  }))
							: [],
				}}
				isSorted={false}
				entriesPerPage={false}
				showTotalEntries={false}
				noEndBorder
			/>
			<ResponsiveDialog
				open={confirmationOpen}
				setOpen={setConfirmationOpen}
				disableClose={true}
				component={
					<ConfirmationComponent
						message={
							"Are you certain you wish to remove this subscriber? Please remember, this action cannot be undone."
						}
						handleConfirm={() => handleDelete(subscriber)}
						handleClose={() => setConfirmationOpen(false)}
					/>
				}
			/>
		</>
	);
}
