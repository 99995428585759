import React, { useState } from "react";
import { Dialog, Grid, Icon, IconButton, useMediaQuery, useTheme } from "@mui/material";
import MDBox from "components/MDBox";
import { ConfirmationComponent } from "./confirmationDialog";

const ResponsiveDialog = ({ open, setOpen, component, minWidth, width, isFormChanged, setIsFormChanged }) => {
	const theme = useTheme();
	const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));
	const [openDialog, setOpenDialog] = useState(false);

	const handleClose = () => {
		if (isFormChanged) {
			setOpenDialog(true);
		} else {
			setOpen(false);
		}
	};

	const handleKeepFormConfirm = () => {
		setOpenDialog(false);
		setOpen(false);
		setIsFormChanged(false);
	};

	const handleFormClose = () => {
		setOpenDialog(false);
		setIsFormChanged(true);
	};

	return (
		<>
			<Dialog
				fullScreen={fullScreen}
				open={open}
				onClose={handleClose}
				PaperProps={{ sx: { minWidth: minWidth ? minWidth : "60%", width: width ? width : "100%" } }}>
				<Grid container px={3} py={1} justifyContent="flex-end">
					<Grid item>
						<IconButton edge="end" color="secondary" onClick={handleClose} aria-label="close">
							<Icon>close</Icon>
						</IconButton>
					</Grid>
				</Grid>
				<MDBox sx={{ width: "100%" }}>{component}</MDBox>
			</Dialog>
			<Dialog
				fullScreen={fullScreen}
				open={openDialog}
				onClose={handleClose}
				PaperProps={{ sx: { minWidth: minWidth ? minWidth : "40%", width: width ? width : "80%" } }}>
				<Grid container px={3} py={1} justifyContent="flex-end">
					<Grid item>
						<IconButton edge="end" color="secondary" onClick={handleClose} aria-label="close">
							<Icon>close</Icon>
						</IconButton>
					</Grid>
				</Grid>
				<ConfirmationComponent
					message={
						"You have some changes that haven't been saved yet. If you leave now, you might lose them. Are you sure you want to continue?"
					}
					handleConfirm={() => handleKeepFormConfirm()}
					handleClose={() => handleFormClose()}
				/>
			</Dialog>
		</>
	);
};

export default ResponsiveDialog;
