import axios from "axios";
import { getAuthToken, removeAuthToken } from "../utils/authToken";

const api = axios.create({
	baseURL: process.env.REACT_APP_ENV === "development" ? "http://localhost:8089/api" : process.env.REACT_APP_API_URL,
	headers: {
		"Content-Type": "application/json",
	},
});

api.interceptors.request.use(
	function (request) {
		const token = getAuthToken();
		if (token) {
			request.headers["Authorization"] = `Bearer ${token}`;
		}
		return request;
	},
	function (error) {
		return Promise.reject(error);
	}
);

api.interceptors.response.use(
	function (response) {
		return response;
	},
	function (error) {
		if (error.response && error.response.status === 401) {
			removeAuthToken();
			window.location.href = "/connect";
		}
		return Promise.reject(error);
	}
);

export default api;
